import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BackgroundGradientAnimation"] */ "/vercel/path0/src/components/atoms/background-gradient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Calendar"] */ "/vercel/path0/src/components/atoms/calendar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/vercel/path0/src/components/atoms/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChartContainer","ChartTooltip","ChartTooltipContent"] */ "/vercel/path0/src/components/atoms/chart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Bars","LineCharts","ChartPie","BarOrLineChart"] */ "/vercel/path0/src/components/atoms/charts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Checkbox"] */ "/vercel/path0/src/components/atoms/checkbox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Combobox"] */ "/vercel/path0/src/components/atoms/combobox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Command","CommandEmpty","CommandGroup","CommandInput","CommandItem","CommandList","CommandSeparator"] */ "/vercel/path0/src/components/atoms/command.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogPortal","DialogOverlay","DialogClose","DialogTrigger","DialogContent","DialogHeader","DialogFooter","DialogTitle","DialogDescription"] */ "/vercel/path0/src/components/atoms/dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuItem","DropdownMenuCheckboxItem","DropdownMenuRadioItem","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuShortcut","DropdownMenuGroup","DropdownMenuPortal","DropdownMenuSub","DropdownMenuSubContent","DropdownMenuSubTrigger","DropdownMenuRadioGroup"] */ "/vercel/path0/src/components/atoms/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/hover-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Label"] */ "/vercel/path0/src/components/atoms/label.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Popover","PopoverTrigger","PopoverContent"] */ "/vercel/path0/src/components/atoms/popover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RadioGroup","RadioGroupItem"] */ "/vercel/path0/src/components/atoms/radio-group.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RangeCalendar"] */ "/vercel/path0/src/components/atoms/range-calendar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ResizablePanelGroup","ResizablePanel","ResizableHandle"] */ "/vercel/path0/src/components/atoms/resizable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea","ScrollBar"] */ "/vercel/path0/src/components/atoms/scroll-area.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SelectGroup","SelectLabel","Select","SelectContent","SelectItem","SelectTrigger","SelectValue"] */ "/vercel/path0/src/components/atoms/select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/src/components/atoms/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetPortal","SheetOverlay","SheetTrigger","SheetClose","SheetContent","SheetHeader","SheetFooter","SheetTitle","SheetDescription"] */ "/vercel/path0/src/components/atoms/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar","SidebarContent","SidebarFooter","SidebarGroup","SidebarGroupAction","SidebarGroupContent","SidebarGroupLabel","SidebarHeader","SidebarInput","SidebarInset","SidebarMenu","SidebarMenuAction","SidebarMenuBadge","SidebarMenuButton","SidebarMenuItem","SidebarMenuSkeleton","SidebarMenuSub","SidebarMenuSubButton","SidebarMenuSubItem","SidebarProvider","SidebarRail","SidebarSeparator","SidebarTrigger","useSidebar"] */ "/vercel/path0/src/components/atoms/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Switch"] */ "/vercel/path0/src/components/atoms/switch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsTrigger","TabsContent"] */ "/vercel/path0/src/components/atoms/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Archive"] */ "/vercel/path0/src/components/molecules/archives.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar"] */ "/vercel/path0/src/components/molecules/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Banner"] */ "/vercel/path0/src/components/molecules/banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonLoading"] */ "/vercel/path0/src/components/molecules/button-loading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["getSheets","getTables"] */ "/vercel/path0/src/components/molecules/dailies-statistics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatePickerWithRange"] */ "/vercel/path0/src/components/molecules/date-range-picker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialogMultiuse"] */ "/vercel/path0/src/components/molecules/dialog-multiuse.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FreemiumBanner"] */ "/vercel/path0/src/components/molecules/freemium-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Nav"] */ "/vercel/path0/src/components/molecules/nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatePicker"] */ "/vercel/path0/src/components/molecules/params/date-picker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Offices"] */ "/vercel/path0/src/components/molecules/params/offices.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PathBreadcrumb"] */ "/vercel/path0/src/components/molecules/path-breadcrumb.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductCard"] */ "/vercel/path0/src/components/molecules/products-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DaysOfWeek"] */ "/vercel/path0/src/components/molecules/schedule-days-week.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdministrationSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/administration/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigurationSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/configuration/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyAccountSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/my-account/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PatientsSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/patients/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReportsSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/reports/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StatisticsSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/statistics/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StaticSearchBar"] */ "/vercel/path0/src/components/molecules/static-search-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/molecules/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BanksForm"] */ "/vercel/path0/src/components/organisms/forms/administration/banks/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CashReconciliationForm"] */ "/vercel/path0/src/components/organisms/forms/administration/cashreconciliation/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DailybalanceForm"] */ "/vercel/path0/src/components/organisms/forms/administration/dailybalance/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteBankForm"] */ "/vercel/path0/src/components/organisms/forms/administration/delete-bank.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteCardForm"] */ "/vercel/path0/src/components/organisms/forms/administration/delete-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteExpenseForm"] */ "/vercel/path0/src/components/organisms/forms/administration/delete-expense.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteIncomeForm"] */ "/vercel/path0/src/components/organisms/forms/administration/delete-income.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteMovementForm"] */ "/vercel/path0/src/components/organisms/forms/administration/delete-movement.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InstallmentsForm"] */ "/vercel/path0/src/components/organisms/forms/administration/installments/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewBankForm"] */ "/vercel/path0/src/components/organisms/forms/administration/new-bank/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewCardForm"] */ "/vercel/path0/src/components/organisms/forms/administration/new-card/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewCashExpenseForm"] */ "/vercel/path0/src/components/organisms/forms/administration/new-cash-expense/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewIncomeForm"] */ "/vercel/path0/src/components/organisms/forms/administration/new-income/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewInvoiceExpenseForm"] */ "/vercel/path0/src/components/organisms/forms/administration/new-invoice-expense/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewMovementForm"] */ "/vercel/path0/src/components/organisms/forms/administration/new-movement/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewPaymentExpenseForm"] */ "/vercel/path0/src/components/organisms/forms/administration/new-supplier-expense/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuppliersForm"] */ "/vercel/path0/src/components/organisms/forms/administration/suppliers/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CopyMedicalInsurancesDataForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/copy-medical-insurances/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteBenefitForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/delete-benefit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteGroupForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/delete-group.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteMedicalInsuranceForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/delete-medical-insurance.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeletePlanForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/delete-plan.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteSupplierForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/delete-supplier.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewMedicalInsuranceBenefitForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/new-medical-insurance/benefit/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewMedicalInsuranceDataForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/new-medical-insurance/data/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewMedicalInsuranceGroupsForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/new-medical-insurance/groups/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewMedicalInsurancePlansForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/new-medical-insurance/plans/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewSupplierForm"] */ "/vercel/path0/src/components/organisms/forms/configuration/new-supplier/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GeneralDataForm"] */ "/vercel/path0/src/components/organisms/forms/general-data/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HolydayForm"] */ "/vercel/path0/src/components/organisms/forms/holyday/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LaboratoriesForm"] */ "/vercel/path0/src/components/organisms/forms/laboratories/data/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ListCopyForm"] */ "/vercel/path0/src/components/organisms/forms/laboratories/list-copy/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ListPriceFrom"] */ "/vercel/path0/src/components/organisms/forms/laboratories/list-price/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Lenders"] */ "/vercel/path0/src/components/organisms/forms/lenders/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/forms/login/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MedInsCopy"] */ "/vercel/path0/src/components/organisms/forms/medical-insurances-copy/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyAccountForm"] */ "/vercel/path0/src/components/organisms/forms/my-account/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OfficesForm"] */ "/vercel/path0/src/components/organisms/forms/offices/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BenefitsPatientForm"] */ "/vercel/path0/src/components/organisms/forms/patients/benefits/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BudgetsForm"] */ "/vercel/path0/src/components/organisms/forms/patients/budgets/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GalleryActions"] */ "/vercel/path0/src/components/organisms/forms/patients/gallery/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PatientForm"] */ "/vercel/path0/src/components/organisms/forms/patients/generals-data/form.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/forms/patients/generals-data/membership/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeleteMedicalHistoryForm"] */ "/vercel/path0/src/components/organisms/forms/patients/medical-history/delete-history/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewMedicalHistoryForm"] */ "/vercel/path0/src/components/organisms/forms/patients/medical-history/new-history/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaymentsForm"] */ "/vercel/path0/src/components/organisms/forms/patients/payments/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrescriptionsForm"] */ "/vercel/path0/src/components/organisms/forms/patients/prescriptions/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AbscencesForm"] */ "/vercel/path0/src/components/organisms/forms/professionals/abscences/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfesionalsForm"] */ "/vercel/path0/src/components/organisms/forms/professionals/data/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TimesTableForm"] */ "/vercel/path0/src/components/organisms/forms/professionals/timetable/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReportsForm"] */ "/vercel/path0/src/components/organisms/forms/reports/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DisabledAppointment"] */ "/vercel/path0/src/components/organisms/forms/schedule/disabled-appointment/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/forms/schedule/edit-observation/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FirstTimeForm"] */ "/vercel/path0/src/components/organisms/forms/schedule/first-time/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewOverturn"] */ "/vercel/path0/src/components/organisms/forms/schedule/overturn/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchForm"] */ "/vercel/path0/src/components/organisms/forms/schedule/search/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SmsForm"] */ "/vercel/path0/src/components/organisms/forms/sms-data/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpecialtyForm"] */ "/vercel/path0/src/components/organisms/forms/specialty/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StatisticsForm"] */ "/vercel/path0/src/components/organisms/forms/statistics/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UsersForm"] */ "/vercel/path0/src/components/organisms/forms/users/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Various"] */ "/vercel/path0/src/components/organisms/forms/various-data/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhatsappForm"] */ "/vercel/path0/src/components/organisms/forms/whatsapp-data/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopNav"] */ "/vercel/path0/src/components/organisms/nav/desktop-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNav"] */ "/vercel/path0/src/components/organisms/nav/mobile-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppointmentsView"] */ "/vercel/path0/src/components/organisms/patients/appointments.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BenefitsView"] */ "/vercel/path0/src/components/organisms/patients/benefits/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BudgetsView"] */ "/vercel/path0/src/components/organisms/patients/budgets/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LaboratoryView"] */ "/vercel/path0/src/components/organisms/patients/laboratory/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MedicalHistoryView"] */ "/vercel/path0/src/components/organisms/patients/medical-history.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OdontogramView"] */ "/vercel/path0/src/components/organisms/patients/odontogram/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaymentsView"] */ "/vercel/path0/src/components/organisms/patients/payments/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrescriptionsView"] */ "/vercel/path0/src/components/organisms/patients/prescriptions/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SummaryReports"] */ "/vercel/path0/src/components/organisms/reports/summary-reports.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScheduleView"] */ "/vercel/path0/src/components/organisms/schedule/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchPatient"] */ "/vercel/path0/src/components/organisms/search-patient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShortCuts"] */ "/vercel/path0/src/components/organisms/shortcuts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BenefitsMadeGraphics"] */ "/vercel/path0/src/components/organisms/statistics/benefits-made.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BudgetsIssuedGraphics"] */ "/vercel/path0/src/components/organisms/statistics/budgets-issued.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ControlPanelGraphics"] */ "/vercel/path0/src/components/organisms/statistics/control-panel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DailiesStatistics"] */ "/vercel/path0/src/components/organisms/statistics/dailies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FinancesGraphics"] */ "/vercel/path0/src/components/organisms/statistics/finances.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WeekliesStatistics"] */ "/vercel/path0/src/components/organisms/statistics/weeklies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/administration/banks/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/administration/cards/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/administration/dailycashflow/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/administration/suppliers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/administration/transactions/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/copy-medical-insurances/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/holyday/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["columns"] */ "/vercel/path0/src/components/organisms/tables/configuration/laboratories/columns.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Data"] */ "/vercel/path0/src/components/organisms/tables/configuration/laboratories/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Columns"] */ "/vercel/path0/src/components/organisms/tables/configuration/laboratories/list-copy/columns.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Columns"] */ "/vercel/path0/src/components/organisms/tables/configuration/laboratories/price-list/columns.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Data"] */ "/vercel/path0/src/components/organisms/tables/configuration/laboratories/price-list/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/medical-insurances-benefits/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/medical-insurances-groups/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/medical-insurances-plans/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/medical-insurances/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["columns"] */ "/vercel/path0/src/components/organisms/tables/configuration/offices/columns.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Data"] */ "/vercel/path0/src/components/organisms/tables/configuration/offices/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/professionals/abscences/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["columns"] */ "/vercel/path0/src/components/organisms/tables/configuration/professionals/columns.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Data"] */ "/vercel/path0/src/components/organisms/tables/configuration/professionals/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/professionals/timetable/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/specialties/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/suppliers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/configuration/users/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/medical-insurances-copy/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/patient/appointments/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Data"] */ "/vercel/path0/src/components/organisms/tables/patient/benefits-filter/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Data"] */ "/vercel/path0/src/components/organisms/tables/patient/benefits/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/patient/budgets/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Data"] */ "/vercel/path0/src/components/organisms/tables/patient/laboratory/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/patient/membership/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Data"] */ "/vercel/path0/src/components/organisms/tables/patient/payments-budget/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/patient/prescriptions/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/reminders-list/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/tables/reminders/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/reports/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/src/components/organisms/tables/schedule/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useAutoSubmit"] */ "/vercel/path0/src/hooks/use-auto-submit.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useDebouncedSearch"] */ "/vercel/path0/src/hooks/use-debounce-search.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useDebounce"] */ "/vercel/path0/src/hooks/use-debounce.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useKeypress"] */ "/vercel/path0/src/hooks/use-keypress.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useOnClickOutside"] */ "/vercel/path0/src/hooks/use-on-click-outside.ts");
;
import(/* webpackMode: "eager", webpackExports: ["usePagination"] */ "/vercel/path0/src/hooks/use-pagination.ts");
;
import(/* webpackMode: "eager", webpackExports: ["usePermissionsView"] */ "/vercel/path0/src/hooks/use-permissions.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useScheduleUpdate"] */ "/vercel/path0/src/hooks/use-schedule-update.ts");
